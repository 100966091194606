import {NextPage, NextPageContext} from 'next';
import {useEffect} from 'react';
import {useSelector} from 'react-redux';
import {LoginDisabled} from '../client/auth/components/LoginDisabled';
import {getCookieFromServer, redirect} from '../client/auth/service/LoginService';
import {LoadingDialog} from '../client/common/components/dialogs/LoadingDialog';
import {Login} from '../client/common/components/login/Login';
import {useAddNotification} from '../client/common/components/notifications/actions/NotificationsActions';
import {NotificationsComponent} from '../client/common/components/notifications/components/NotificationsComponent';
import {TransitionSlideUp} from '../client/common/components/transition/TransitionSlideUp';
import {Store} from '../client/lib/StoreType';
import {NotificationType} from '../client/models/notification/NotificationModel';

const LoginPage: NextPage = () => {
    const {errorLogin, loginConfigDisabled, isFetchingLogin} = useSelector((state: Store) => state.common);

    const {addNotification, closeNotification} = useAddNotification();
    const {notifications} = useSelector((state: Store) => state.notifications);
    useEffect(() => {
        if (errorLogin) {
            addNotification({type: NotificationType.ERROR, text: errorLogin.message});
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [errorLogin]);

    if (loginConfigDisabled) {
        // Show page with login disabled info
        return <LoginDisabled />;
    }

    return !loginConfigDisabled ? (
        <>
            <Login />
            <LoadingDialog open={isFetchingLogin ?? false} TransitionComponent={TransitionSlideUp} description={'ucet.prihlaseni.loading.description'} />
            <NotificationsComponent notifications={notifications} onClose={closeNotification} />
        </>
    ) : null;
};

export default LoginPage;

LoginPage.getInitialProps = async (ctx: NextPageContext) => {
    const cookie = getCookieFromServer(ctx.req);
    if (!!cookie && ctx.pathname === '/login') {
        redirect('/', ctx?.res);
    }

    return {
        props: {},
    };
};
