import Button from '@mui/material/Button';
import getConfig from 'next/config';
import {useRouter} from 'next/router';
import {FormattedMessage} from 'react-intl';
import {isNotNullOrUndefinedOrEmpty} from '../../utils/CommonUtils';
import {LandingLayout} from '../layout/LandingLayout';

const publicRuntimeConfig = getConfig().publicRuntimeConfig;

export const Login = () => {
    const {push} = useRouter();

    const handleLogin = () => {
        if (isNotNullOrUndefinedOrEmpty(publicRuntimeConfig.FRONTEND_IDM_ENDPOINT)) {
            const uri = `${publicRuntimeConfig.FRONTEND_IDM_ENDPOINT}/?redirect_uri=${publicRuntimeConfig.SPP_REDIRECT_URI}&client_id=${publicRuntimeConfig.SPP_CLIENT_ID}&scope=spp&response_type=code&app=spp`;
            push(uri);
        }
    };

    return (
        <LandingLayout>
            <Button
                onClick={handleLogin}
                variant="contained"
                fullWidth
                size="large"
                sx={(theme) => ({
                    backgroundColor: theme.palette.common.white,
                    color: theme.palette.primary.main,
                    opacity: 1,
                    maxWidth: 200,
                    margin: 'auto',
                    '&$disabled': {
                        backgroundColor: '#F7F7FA',
                        color: theme.palette.common.white,
                    },
                    '&:hover': {
                        backgroundColor: theme.palette.secondary.main,
                        color: theme.palette.common.white,
                    },
                })}
            >
                <FormattedMessage id="button.prihlasit" />
            </Button>
        </LandingLayout>
    );
};
