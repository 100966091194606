import {styled, Typography} from '@mui/material';
import {FC, ReactNode, useEffect, useState} from 'react';
import {FormattedMessage} from 'react-intl';
import {useScreenOrientation} from '../../service/CommonService';
import {getDeviceType, LandingBackground} from './LandingBackground';

declare const window: any;

const Div = styled('div')<{readonly width: number; readonly height: number}>(({theme, width, height}) => ({
    width: width > 800 ? undefined : 450,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingTop: height > 900 ? theme.spacing(1) : theme.spacing(5),
    marginLeft: getDeviceType() === 'desktop' ? theme.spacing(7) : undefined,
    zoom: height < 700 || getDeviceType() === 'mobile' ? '85%' : undefined,
}));

type Props = {
    readonly children: ReactNode;
};

/**
 * Wrapper komponenta pro přihlášení a reset hesla
 */
export const LandingLayout: FC<Props> = ({children}) => {
    const orientation = useScreenOrientation();
    const [width, setWidth] = useState<number>(900);
    const [height, setHeight] = useState<number>(1000);

    useEffect(() => {
        if (typeof window !== 'undefined') {
            setWidth(window.innerWidth);
            setHeight(window.innerHeight);
        }
    }, []);
    const variant = width > 1200 ? 'h2' : 'h3';
    return (
        <LandingBackground>
            <Div width={width} height={height}>
                <Typography
                    sx={{
                        color: 'common.white',
                        textAlign: 'center',
                        marginBottom: 5,
                        width: getDeviceType() === 'mobile' && orientation === 'portrait-primary' ? '70%' : '100%',
                    }}
                    variant={getDeviceType() === 'mobile' && orientation === 'landscape-primary' ? 'h4' : variant}
                >
                    <FormattedMessage id="portal" />
                </Typography>
                {children}
            </Div>
        </LandingBackground>
    );
};
